(function($) {
	//on load
	$(window).on('load', function () {
	
	});

	//on ready
	$(document).ready(function() {

		/*** Open mobile menu ***/

		$(".open-menu-btn").click(function(e){
			$(".menu").toggleClass("active");
			e.stopPropagation();
		})

		$(document).on("click", function(e) {
            if (!$(".menu").has(e.target).length && !$(".menu").is(e.target)) {
              $(".menu").removeClass("active");
            }
		});


		/*** Sliders ***/

		$(".slider").each(function(){

			if( $(this).children().length > 1 ){

				var slickInduvidual = $(this);

				var $count = $(this).siblings(".slider-footer").find(".slider-count");
				var $slickElement = $(this);

				$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
					var i = (currentSlide ? currentSlide : 0) + 1;
					$count.text(i + ' / ' + slick.slideCount);
				});

				slickInduvidual.slick({
					dots: false,
					infinite: true,
					speed: 1000,
					slidesToShow: 1,
					autoplay: true,
					autoplaySpeed: 4000,
					responsive: [
						{
							breakpoint: 1100,
							settings: {
								arrows: false,
							}
						},
					]
				});

				$(".slider-count").show();

			}
			else{
				$(this).addClass("single-element");
			}

		})

	

	}); //END on ready

})(jQuery);